import {
    CloseOutlined as AntdCloseOutlined,
    FilterOutlined as AntdFilterOutlined
} from '@ant-design/icons'
import { Button as AntdButton } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './Filter.less'
import TagsList from './TagsList/TagsList'

interface PropsFilter {
    isVisible: boolean
    setVisible: () => void
}

function Filter(props: PropsFilter): React.ReactElement {
    const i18nextTranslate = useTranslation('common').t
    return (
        <>
            {props.isVisible ? (
                <>
                    <AntdButton
                        className="CloseButton"
                        onClick={(): void => props.setVisible()}
                        type="ghost"
                    >
                        <AntdCloseOutlined className="Medium" />
                    </AntdButton>
                    <TagsList />
                </>
            ) : (
                <AntdButton
                    className="FilterButton"
                    type="ghost"
                    onClick={(): void => props.setVisible()}
                >
                    <AntdFilterOutlined className="Medium" />
                    {i18nextTranslate('filters')}
                </AntdButton>
            )}
        </>
    )
}

export default Filter
