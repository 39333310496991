import React, { createContext, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch as ReactRouterSwitch,
  Route as ReactRouterRoute,
  Redirect
} from 'react-router-dom'
import jwt from 'jwt-decode'

import Authentication from 'Components/Authentication/Authentication'
import Home from 'Components/Home/Home'
import Documentation from 'Components/Documentation/Documentation'
import Management from 'Components/Management/Management'
import axios from 'axios'

import { init, backendUrlBuilder } from 'Utils/api'
import ErrorPage from 'Components/ErrorPage/ErrorPage'
import Tools from 'Components/Tools/Tools'
import Header from 'Components/Header/Header'

export const userContext = createContext<UserInfo>({isAdmin:false, userinfo:{sub:"", groups:[], name:"", email:""}})

export interface UserInfo {
  isAdmin: boolean,
  userinfo: {
    sub: string;
    email: string;
    name: string;
    groups: string[];
  }
}

export interface JWTPayload {
  userInfo: UserInfo;
  iat: number;
  exp: number;
}

interface UserAuthenticateState {
  isAuthenticated: boolean
  user: UserInfo
}

function AppRoutes(): React.ReactElement {
  const [userAuthenticateState, setUserAuthenticateState] = useState<UserAuthenticateState>({
    isAuthenticated: false,
    user: {isAdmin: false, userinfo:{sub:"", email:"", name:"", groups:[]}},
  })

  const [currentHeaderMenuKey, setCurrentHeaderMenuKey] = useState<string>('')

  useEffect(() => {
    const prevAccessToken = localStorage.getItem('accessToken')
    const newAccessToken = window.location.search.substring(1)
    const accessToken =
      prevAccessToken !== null && !newAccessToken ? prevAccessToken : newAccessToken

    const urlVerifyToken = backendUrlBuilder('auth/token/verify')
    if (!urlVerifyToken) {
      throw new Error(`Badly initialized environment: wrong back url ${urlVerifyToken}`)
    }
    axios
      .get<UserAuthenticateState>(urlVerifyToken, {
        headers: {
          Authorization: `Token ${accessToken}`
        }
      })
      .then((resp) => {
        const token = jwt<JWTPayload>(accessToken);
        localStorage.setItem('accessToken', accessToken)
        init()
        setUserAuthenticateState({
          isAuthenticated: true,
          user : {
            isAdmin: token.userInfo.userinfo.groups.includes(process.env.REACT_APP_ADMINGroup? process.env.REACT_APP_ADMINGroup: "grp-app-citadel-admins"),
            userinfo: token.userInfo.userinfo,
          }
        })
      })
      .catch(() => {
        setUserAuthenticateState({ isAuthenticated: false, user:{isAdmin:false,userinfo:{sub:"", name:"", email:"", groups:[]}} })
      })

    setCurrentHeaderMenuKey(localStorage.getItem('headerMenuKey') || '')
  }, [])

  React.useEffect(() => {
    localStorage.setItem('headerMenuKey', currentHeaderMenuKey)
  }, [currentHeaderMenuKey])

  return userAuthenticateState.isAuthenticated ? (
    <userContext.Provider value={userAuthenticateState.user}>
      <Router>
        <ReactRouterSwitch>
          <ReactRouterRoute exact={true} path={['/home', '/']}>
            <Header
              selectedKey={currentHeaderMenuKey}
              setSelectedKey={setCurrentHeaderMenuKey}
              displayMenu={false}
            />
            <Home setSelectedKey={setCurrentHeaderMenuKey} />
          </ReactRouterRoute>

          <ReactRouterRoute exact={true} path="/tools">
            <Header
              selectedKey={currentHeaderMenuKey}
              setSelectedKey={setCurrentHeaderMenuKey}
            />
            <Tools />
          </ReactRouterRoute>

          <ReactRouterRoute exact={true} path="/documentation">
            <Header
              selectedKey={currentHeaderMenuKey}
              setSelectedKey={setCurrentHeaderMenuKey}
            />
            <Documentation />
          </ReactRouterRoute>

          <ReactRouterRoute exact={true} path="/management">
            <Header
              selectedKey={currentHeaderMenuKey}
              setSelectedKey={setCurrentHeaderMenuKey}
            />
            {userAuthenticateState.user.isAdmin ? <Management /> : <Redirect to="/home" />}
          </ReactRouterRoute>

          <ReactRouterRoute exact={true} path="/404">
            <ErrorPage errorNumber={404} />
          </ReactRouterRoute>

          <ReactRouterRoute path="/">
            <Redirect to="/404" />
          </ReactRouterRoute>
        </ReactRouterSwitch>
      </Router>
    </userContext.Provider>
  ) : (
    <Router>
      <ReactRouterSwitch>
        <ReactRouterRoute path="/">
          <Authentication />
        </ReactRouterRoute>
      </ReactRouterSwitch>
    </Router>
  )
}

export default AppRoutes
