/* TODO: Remove this */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Button as AntdButton,
    Col as AntdCol,
    Form as AntdForm,
    Input as AntdInput,
    Row as AntdRow
} from 'antd'
import { EditFilled as AntdEditFilled, DeleteFilled as AntdDeleteFilled } from '@ant-design/icons'

import './InstanceField.less'

import { Instance } from 'Utils/Type'
import AutoCompleteField from '../AutoCompleteField/AutoCompleteField'
import { Store } from 'antd/lib/form/interface'

import { getInstanceNames } from 'Utils/generalFunction'
import { TAGS_CATEGORIES } from 'Components/Management/ToolsManagement/ToolsManagement'

interface PropsInstanceField {
    id: number
    instance: Instance
    instances: Array<Instance>
    instancesInEditMode: Array<number>
    removeInstance: (id: number) => void
    updateInstance: (id: number, instance: Instance) => void
    addToInstancesInEdition: (id: number) => void
    reset: boolean
}

function InstanceField(props: PropsInstanceField): React.ReactElement {
    const tagsList = useContext(TAGS_CATEGORIES).tags

    const i18nextTranslate = useTranslation(['common', 'errors', 'tag']).t

    const isInEditMode = props.instancesInEditMode.some((value) => value === props.id)
    const [form] = AntdForm.useForm()

    useEffect(() => form.resetFields(), [props.reset, form])

    function onFinish(values: Store): void {
        const instance: Instance = {
            id: props.instance.id,
            name: values[`instanceName ` + props.id],
            url: values[`url ` + props.id],
            countryName:
                values[`countryName ` + props.id] && values[`countryName` + props.id] !== 'All'
                    ? values[`countryName ` + props.id].toLowerCase()
                    : '',
            environmentName: values[`environmentName ` + props.id]
                ? values[`environmentName ` + props.id].toLowerCase()
                : ''
        }
        props.updateInstance(props.id, instance)
    }

    return (
        <AntdForm
            form={form}
            onFinish={onFinish}
            hideRequiredMark={!isInEditMode}
            className="InstanceForm"
        >
            <AntdInput.Group>
                <AntdRow justify="space-between">
                    <AntdCol>
                        <AntdForm.Item
                            label={i18nextTranslate('common:name')}
                            name={`instanceName ` + props.id}
                            initialValue={props.instance.name}
                            rules={[
                                {
                                    required: true,
                                    message: i18nextTranslate('errors:emptyInstanceName')
                                },
                                {
                                    validator: (_, value: string): Promise<void> => {
                                        return getInstanceNames(props.instances).includes(value) &&
                                            value !== props.instance.name
                                            ? Promise.reject(
                                                  i18nextTranslate(
                                                      'errors:instanceNameAlreadyExists'
                                                  )
                                              )
                                            : Promise.resolve()
                                    }
                                }
                            ]}
                        >
                            <AntdInput
                                placeholder={i18nextTranslate('common:name')}
                                readOnly={!isInEditMode}
                            />
                        </AntdForm.Item>
                    </AntdCol>

                    <AntdCol>
                        <AutoCompleteField
                            initialValue={
                                props.instance.countryName
                                    ? props.instance.countryName
                                    : i18nextTranslate('tag:all')
                            }
                            values={tagsList.countries}
                            placeholder={i18nextTranslate('tag:country')}
                            name={`countryName ` + props.id}
                            readOnly={!isInEditMode}
                            required={false}
                        />
                    </AntdCol>

                    <AntdCol>
                        <AutoCompleteField
                            initialValue={props.instance.environmentName}
                            values={tagsList.environments}
                            placeholder={i18nextTranslate('tag:environment')}
                            name={`environmentName ` + props.id}
                            readOnly={!isInEditMode}
                            required={false}
                        />
                    </AntdCol>

                    <AntdCol>
                        <div>
                            {isInEditMode ? (
                                <AntdButton
                                    type="primary"
                                    onClick={(): void => {
                                        form.submit()
                                    }}
                                >
                                    Save
                                </AntdButton>
                            ) : (
                                <AntdButton
                                    type="link"
                                    onClick={(): void => {
                                        props.addToInstancesInEdition(props.id)
                                    }}
                                    className="GreyHover"
                                    icon={<AntdEditFilled className="MediumIcon " />}
                                />
                            )}

                            <AntdButton
                                type="link"
                                onClick={(): void => {
                                    props.removeInstance(props.id)
                                }}
                                icon={<AntdDeleteFilled className="MediumIcon " />}
                            />
                        </div>
                    </AntdCol>
                </AntdRow>

                <AntdForm.Item
                    label={'Url'}
                    name={`url ` + props.id}
                    initialValue={props.instance.url}
                    rules={[
                        {
                            required: true,
                            message: i18nextTranslate('errors:emptyInstanceUrl')
                        }
                    ]}
                >
                    <AntdInput
                        placeholder={i18nextTranslate('common:url')}
                        readOnly={!isInEditMode}
                    />
                </AntdForm.Item>
            </AntdInput.Group>
        </AntdForm>
    )
}

export default InstanceField
