// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Assets/C+_FOND_FIXE_NEUTRE_03.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".BackgroundAuthentication {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  min-height: 100vh;\n}\n.Authentication {\n  background: #ffffff;\n  padding: 80px 50px;\n  border: 1px solid #bdbdbd;\n  border-radius: 4px;\n}\n.App {\n  text-align: center;\n}\n", ""]);
// Exports
module.exports = exports;
