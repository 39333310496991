import { useEffect, useState } from 'react'

export function useApi<DataType>(
    promiseAnswer: Promise<DataType> | undefined,
    initialData: DataType
): {
    error: Error | null
    isLoaded: boolean
    data: DataType
    refetch: () => void
} {
    const [error, setError] = useState<Error | null>(null)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [data, setData] = useState<DataType>(initialData)
    const [shouldRefetch, setShouldRefetch] = useState({})

    useEffect(() => {
        setIsLoaded(false)
        setError(null)

        if (promiseAnswer === undefined) {
            setError(new Error('Request null'))
        } else {
            promiseAnswer.then(
                (response) => {
                    setIsLoaded(true)
                    setData(response)
                },
                (error) => {
                    setIsLoaded(true)
                    setError(error)
                }
            )
        }
        // TODO: check why the dep injection here broke the whole mecanism
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRefetch])

    function refetch(): void {
        setShouldRefetch({})
    }

    return { error, isLoaded, data, refetch }
}

export default useApi
