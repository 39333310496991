import React from 'react'
import { Row as AntdRow, Col as AntdCol, Popover as AntdPopover } from 'antd'

import { ArrowRightOutlined as AntdArrowRightOutlined } from '@ant-design/icons'

import './Instance.less'

import { useTranslation } from 'react-i18next'

interface PropsInstance {
    country: string
    environments: Array<{
        [id: string]: Array<{ instanceName: string; url: string }>
    }>
}

function Instance(props: PropsInstance): React.ReactElement {
    const i18nextTranslate = useTranslation('tag').t
    return (
        <AntdRow className="InstanceArray">
            <AntdCol className="Countries" span={12}>
                <span>{i18nextTranslate(props.country)}</span>
            </AntdCol>

            <AntdCol className="ToolTags"  span={12}>
                {props.environments.map((environment) => {
                    const environmentName = Object.keys(environment)[0]
                    const instances = environment[environmentName]

                    return (
                        <AntdRow key={environmentName}>
                            {instances.length === 1 ? (
                                <a
                                    href={instances[0].url}
                                    className="Environment"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span>{i18nextTranslate(environmentName) || '\u0000'}</span>
                                    <AntdArrowRightOutlined />
                                </a>
                            ) : (
                                <AntdPopover
                                    content={
                                        <div className="Instance">
                                            {instances.map(({ url, instanceName }) => (
                                                <a
                                                    href={url}
                                                    key={instanceName}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {instanceName}
                                                </a>
                                            ))}
                                        </div>
                                    }
                                    trigger="click"
                                    placement="right"
                                >
                                    <div className="Environment">
                                        <span>{i18nextTranslate(environmentName) || '\u0000'}</span>
                                        <AntdArrowRightOutlined />
                                    </div>
                                </AntdPopover>
                            )}
                        </AntdRow>
                    )
                })}
            </AntdCol>
        </AntdRow>
    )
}

export default Instance
