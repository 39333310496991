import CardsList from 'Components/CardsList/CardsList'
import React, { useContext } from 'react'
import { Tool } from 'Utils/Type'
import './ToolsList.less'
import { userContext } from 'App/AppRoutes'

interface PropsToolsList {
    tools: Array<Tool>
    editable?: boolean
}
interface Accumulator {
    [id: string]: Array<Tool>
}

function ToolsList(props: PropsToolsList): React.ReactElement {
    function GroupByCategory(list: Array<Tool>): Accumulator {
        const isAdmin = useContext(userContext).isAdmin
        const userGroups = useContext(userContext).userinfo.groups

        if (
            !isAdmin &&
            !userGroups.includes(
                process.env.REACT_APP_INTERNECGroup
                    ? process.env.REACT_APP_INTERNECGroup
                    : 'grp-app-citadel-all-users'
            )
        ) {
            // Filter all tools to get only the ones that match the user's vision
            list = list.filter(function(tool) {
                let instanceInCountry = false
                // Filter tool instances to remove those that are not in the user's vision
                tool.instances = tool.instances.filter((i) => {
                    // Check that the country name is included in one of the user's groups
                    if (
                        userGroups.some((g) => g.includes(i.countryName)) ||
                        i.countryName.toLowerCase().includes('all')
                    ) {
                        instanceInCountry = true
                        return true
                    }
                    return false
                })
                return instanceInCountry
            })
        }

        return list.reduce(function(acc: Accumulator, obj) {
            const categoryName = obj.category

            if (acc[categoryName] === undefined) {
                acc[categoryName] = []
            }
            acc[categoryName].push(obj)

            return acc
        }, {})
    }

    return (
        <div className="ToolsList">
            {Object.entries(GroupByCategory(props.tools)).map(([category, tools]) => {
                return (
                    <CardsList
                        key={category}
                        category={category}
                        listOfTools={tools}
                        editable={props.editable}
                    />
                )
            })}
        </div>
    )
}

export default ToolsList
