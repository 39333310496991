import { Tags, Tool, Instance } from './Type'

export function getTags(tools: Array<Tool>): Tags {
    const temp: Tags = {
        countries: ['all'],
        environments: []
    }

    for (const tool of tools) {
        for (const instance of tool.instances) {
            if (instance.countryName && !temp.countries.includes(instance.countryName)) {
                temp.countries.push(instance.countryName)
            }
            if (instance.environmentName && !temp.environments.includes(instance.environmentName)) {
                temp.environments.push(instance.environmentName)
            }
        }
    }

    return temp
}

export function getToolNames(tools: Array<Tool>): Array<string> {
    const toolNames: Array<string> = []

    for (const tool of tools) {
        if (!toolNames.includes(tool.name)) {
            toolNames.push(tool.name)
        }
    }
    return toolNames
}

export function getInstanceNames(instances: Array<Instance>): Array<string> {
    const instanceNames: Array<string> = []

    for (const instance of instances) {
        if (!instanceNames.includes(instance.name)) {
            instanceNames.push(instance.name)
        }
    }
    return instanceNames
}

export function getCategories(tools: Array<Tool>): Array<string> {
    const temp: Array<string> = []

    for (const tool of tools) {
        if (!temp.includes(tool.category)) {
            temp.push(tool.category)
        }
    }

    return temp
}

export function searchInTools(search: string, tools: Array<Tool>): Array<Tool> {
    return tools.filter((tool) => tool.name.toLowerCase().includes(search.toLowerCase()))
}

export function toolsWithTags(tags: Tags, tools: Array<Tool>): Array<Tool> {
    const toolsToReturn: Array<Tool> = []

    for (const tool of tools) {
        const instances = tool.instances.filter(
            (instance) =>
                (tags.countries.length === 0 ||
                    !instance.countryName ||
                    tags.countries.includes(instance.countryName)) &&
                (tags.environments.length === 0 ||
                    (instance.environmentName &&
                        tags.environments.includes(instance.environmentName)))
        )
        if (instances.length > 0) {
            toolsToReturn.push({ ...tool, instances: instances })
        }
    }

    return toolsToReturn
}
