import { userContext } from 'App/AppRoutes'
import React, { useContext } from 'react'
import { Category } from 'Utils/Type'
import { TAGS } from '../../NavBar'
import Tags from './Tags/Tags'
import './TagsList.less'

function TagsList(): React.ReactElement {
    const TagsList = useContext(TAGS)
    const isAdmin = useContext(userContext).isAdmin
    return (
        <div className="FilterWindow SlideIn">
            <div className="TagsList">
                {isAdmin ? (
                    Object.entries(TagsList).map(([categoryName, tags]) => (
                        <Tags
                            key={categoryName}
                            category={categoryName as Category}
                            listTags={tags as Array<string>}
                        />
                    ))) : (
                    Object.entries(TagsList).filter(([categoryName, tags]) => categoryName !== "countries").map(([categoryName, tags]) => (
                        <Tags
                            key={categoryName}
                            category={categoryName as Category}
                            listTags={tags as Array<string>}
                        />
                    )))
                }
            </div>
        </div>
    )
}

export default TagsList
