import { Button as AntdButton, Card as AntdCard } from 'antd'
import { DeleteFilled as AntdDeleteFilled } from '@ant-design/icons'
import React, { useContext } from 'react'

import ToolEditingModal from 'Components/ToolEditingModal/ToolEditingModal'
import { update } from 'Components/Management/ToolsManagement/ToolsManagement'

import { Tool, Instance } from 'Utils/Type'
import { tool } from 'Utils/api'

import './Cards.less'

import InstanceTag from './Instance/Instance'
import { errorNotification, successNotification } from 'Components/Notifications/Notifications'
import { useTranslation } from 'react-i18next'

interface PropsCards {
    editable?: boolean
    tool: Tool
}

interface Accumulator {
    [id: string]: Array<{
        [id: string]: Array<{ instanceName: string; url: string }>
    }>
}

function Cards(props: PropsCards): React.ReactElement {
    const refresh = useContext(update)

    const i18nextTranslate = useTranslation('success').t

    function groupByCountry(instances: Array<Instance>): Accumulator {
        const acc: Accumulator = {}
        for (const obj of instances) {
            const country: string = obj.countryName || 'all'

            if (!(country in acc)) {
                acc[country] = []
            }

            const envName = obj.environmentName

            const instance = { instanceName: obj.name, url: obj.url }
            const environment = acc[country].find((inst) => inst[envName])

            if (environment === undefined) {
                acc[country].push({ [envName]: [instance] })
                continue
            }

            environment[envName].push(instance)
        }
        return acc
    }

    return (
        <AntdCard
            title={
                <>
                    <div className="CardTitle">
                        <span>{props.tool.name}</span>
                    </div>
                    {props.editable && (
                        <div>
                            <ToolEditingModal tool={props.tool} />
                            <AntdButton
                                type="link"
                                onClick={(): void => {
                                    if (!props.tool.id) {
                                        throw new Error('Something went wrong!')
                                    }
                                    tool?.deleteTool(props.tool.id)
                                        .then(() => {
                                            refresh()
                                            successNotification(
                                                i18nextTranslate('toolDeleted'),
                                                `${props.tool.name} ${i18nextTranslate(
                                                    'hasBeenDeleted'
                                                )}`
                                            )
                                        })
                                        .catch((error: Error) => errorNotification(error.message))
                                }}
                                icon={<AntdDeleteFilled className="Medium" />}
                            />
                        </div>
                    )}
                </>
            }
            bordered={false}
            className={'Cards'}
        >
            <p className="Description">{props.tool.description}</p>

            {Object.entries(groupByCountry(props.tool.instances)).map(
                ([countryName, environments]) => {
                    if (environments !== undefined) {
                        return (
                            <InstanceTag
                                key={countryName}
                                country={countryName}
                                environments={environments}
                            />
                        )
                    }
                    return null
                }
            )}
        </AntdCard>
    )
}

export default Cards
