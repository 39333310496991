import { Menu } from 'antd'
import Text from 'antd/lib/typography/Text'
import { MenuInfo } from 'rc-menu/lib/interface'
import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import './NavTabs.less'

interface NavTabsProps {
    selectedKey: string
    onClick: (e: MenuInfo) => void
}

function NavTabs({ selectedKey, onClick }: NavTabsProps): React.ReactElement {
    const keys = ['Tools', 'Documentation']

    return (
        <Menu onClick={onClick} mode="horizontal" className="NavMenu">
            {keys.map((key) => {
                const lowerKey = key.toLowerCase()
                return (
                    <Menu.Item
                        key={lowerKey}
                        className={lowerKey === selectedKey ? 'UnderlineRed' : ''}
                    >
                        <ReactRouterLink to={'/' + lowerKey}>
                            <Text
                                className={
                                    'UpperCase Medium ' +
                                    (lowerKey === selectedKey ? 'Red' : 'White HoverRed')
                                }
                            >
                                {key}
                            </Text>
                        </ReactRouterLink>
                    </Menu.Item>
                )
            })}
        </Menu>
    )
}

export default NavTabs
