import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import commonEn from 'Assets/Translation/en/common.json'
import errorsEn from 'Assets/Translation/en/errors.json'
import toolDescriptionEn from 'Assets/Translation/en/toolDescription.json'
import tagEn from 'Assets/Translation/en/tag.json'
import successEn from 'Assets/Translation/en/success.json'

void i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        ns: {
            namespaces: ['tag', 'toolDescription', 'common', 'errors', 'success'],
            defaultNS: 'common'
        },
        resources: {
            en: {
                common: commonEn,
                errors: errorsEn,
                tag: tagEn,
                toolDescription: toolDescriptionEn,
                success: successEn
            }
        },
        lng: 'en',
        fallbackLng: 'en'
    })

export default i18n
