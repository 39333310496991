import React from 'react'

import { Spin as AntdSpin } from 'antd'
import { errorNotification } from 'Components/Notifications/Notifications'

interface PropsDisplayData {
    error: Error | null
    isLoaded: boolean
    component: React.ReactElement | Array<React.ReactElement>
}

function DisplayData(props: PropsDisplayData): React.ReactElement {
    if (!props.isLoaded) {
        return <AntdSpin />
    }
    if (props.error !== null) {
        errorNotification(props.error.message)
        return <></>
    }

    return <>{props.component}</>
}

export default DisplayData
