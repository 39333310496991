import { Popover as AntdPopover } from 'antd'
import {    
    UserOutlined as AntdUserOutlined,
    SettingOutlined as AntdSettingOutlined
} from '@ant-design/icons'
import React, { useContext } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './Options.less'
import ProfilMenu from './ProfilMenu/ProfilMenu'
import useApi from 'Utils/API/apiRequest'
import { general } from 'Utils/api'

import { userContext } from 'App/AppRoutes'

interface OptionsProps {
    onClick: () => void
}

function Options({ onClick }: OptionsProps): React.ReactElement {
    const i18nextTranslate = useTranslation('common').t

    const { data } = useApi<{ name: string; email: string }>(general?.getInfo(), {
        name: '',
        email: ''
    })

    const isAdmin = useContext(userContext).isAdmin

    return (
        <div className="Options">
            {isAdmin && (
                <>
                    <ReactRouterLink to="/management" onClick={onClick}>
                        <AntdSettingOutlined className="White Big" />
                    </ReactRouterLink>
                </>
            )}

            <AntdPopover
                content={ProfilMenu}
                title={`${i18nextTranslate('hello')}  ${data.email}`}
                trigger="click"
            >
                <AntdUserOutlined className="White Big" />
            </AntdPopover>
        </div>
    )
}
export default Options
