import React from 'react'

type svgProps = {
    className: string
}

export default ({ className }: svgProps) => (
    <span className={className}>
        <svg
            width="74"
            height="57"
            viewBox="0 0 74 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M67 1.66667C63.3 0.5 59.2333 0 55.3333 0C48.8333 0 41.8333 1.33333 37 5C32.1667 1.33333 25.1667 0 18.6667 0C12.1667 0 5.16667 1.33333 0.333334 5V53.8333C0.333334 54.6667 1.16667 55.5 2 55.5C2.33333 55.5 2.5 55.3333 2.83333 55.3333C7.33333 53.1667 13.8333 51.6667 18.6667 51.6667C25.1667 51.6667 32.1667 53 37 56.6667C41.5 53.8333 49.6667 51.6667 55.3333 51.6667C60.8333 51.6667 66.5 52.6667 71.1667 55.1667C71.5 55.3333 71.6667 55.3333 72 55.3333C72.8333 55.3333 73.6667 54.5 73.6667 53.6667V5C71.6667 3.5 69.5 2.5 67 1.66667ZM67 46.6667C63.3333 45.5 59.3333 45 55.3333 45C49.6667 45 41.5 47.1667 37 50V11.6667C41.5 8.83333 49.6667 6.66667 55.3333 6.66667C59.3333 6.66667 63.3333 7.16667 67 8.33333V46.6667Z"
                fill="#858585"
            />
        </svg>
    </span>
)
