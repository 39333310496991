/* TODO: Remove this */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react'

import { useTranslation } from 'react-i18next'

import { Form as AntdForm, Input as AntdInput, AutoComplete as AntdAutoComplete } from 'antd'
import { DownOutlined as AntdDownOutlined } from '@ant-design/icons'

interface PropsAutoCompleteField {
    initialValue: string | undefined
    values: Array<string>
    placeholder: string
    name: string
    readOnly?: boolean
    required: boolean
}

function AutoCompleteField(props: PropsAutoCompleteField): React.ReactElement {
    const i18nextTranslate = useTranslation('tag').t
    if (props.readOnly) {
        return (
            <AntdForm.Item
                name={props.name}
                initialValue={i18nextTranslate(props.initialValue || '')}
                label={props.placeholder}
            >
                <AntdInput readOnly={true} />
            </AntdForm.Item>
        )
    }
    return (
        <AntdForm.Item
            label={props.placeholder}
            name={props.name}
            initialValue={i18nextTranslate(props.initialValue || '')}
            rules={[
                {
                    required: props.required,
                    message: 'Please input a value for this field!'
                }
            ]}
        >
            <AntdAutoComplete
                options={props.values.map((value) => {
                    return {
                        value: i18nextTranslate(value),
                        label: <>{i18nextTranslate(value)}</>
                    }
                })}
            >
                <AntdInput suffix={<AntdDownOutlined />} placeholder={props.placeholder} />
            </AntdAutoComplete>
        </AntdForm.Item>
    )
}

export default AutoCompleteField
