import { notification as antdNotification } from 'antd'

export const errorNotification = (description: string): void => {
    antdNotification.error({
        message: 'Error',
        description: description
    })
}

export const successNotification = (title: string, description: string): void => {
    antdNotification.success({
        message: title,
        description: description
    })
}
