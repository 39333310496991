import { Card, Col, Row } from 'antd'
import Text from 'antd/lib/typography/Text'
import BookIcon from 'Assets/Icons/BookIcon'
import ToolsIcon from 'Assets/Icons/ToolsIcon'
import React, { createContext, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import './Home.less'

export const searchContext = createContext<string>('')

interface PropsHome {
    setSelectedKey: (value: string) => void
}

function HomePage({ setSelectedKey }: PropsHome): React.ReactElement {
    const [searchValue] = useState<string>('')
    return (
        <searchContext.Provider value={searchValue}>
            <div className="Home">
                <Row gutter={16}>
                    <Col span={12}>
                        <ReactRouterLink to="/tools" onClick={() => setSelectedKey('tools')}>
                            <Card
                                cover={<ToolsIcon className="ImageCard" />}
                                className="Cards HomeCards"
                            >
                                <h2 className="UpperCase Medium Black TitleCard Bold">Tools</h2>
                                <Text className="Normal Black">
                                    Access the tools for your platform configuration and monitoring
                                </Text>
                            </Card>
                        </ReactRouterLink>
                    </Col>
                    <Col span={12}>
                        <ReactRouterLink
                            to="/documentation"
                            onClick={() => setSelectedKey('documentation')}
                        >
                            <Card
                                cover={<BookIcon className="ImageCard" />}
                                className="Cards HomeCards"
                            >
                                <h2 className="UpperCase Medium Black TitleCard Bold">
                                    Documentation
                                </h2>
                                <Text className="Normal Black">
                                    Consult interfaces documentation and tools user guide
                                </Text>
                            </Card>
                        </ReactRouterLink>
                    </Col>
                </Row>
            </div>
        </searchContext.Provider>
    )
}

export default HomePage
