import React from 'react'

import { Button as AntdButton } from 'antd'

import { useTranslation } from 'react-i18next'

import { backendUrlBuilder } from 'Utils/api'

function ProfilMenu(): React.ReactElement {
    const i18nextTranslate = useTranslation('common').t
    return (
        <a href={backendUrlBuilder('auth/logout')}>
            <AntdButton
                type="primary"
                className="UpperCase"
                onClick={(): void => localStorage.clear()}
            >
                {i18nextTranslate('logOut')}
            </AntdButton>
        </a>
    )
}
export default ProfilMenu
