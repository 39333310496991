import { Row as AntdRow } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DisplayData from 'Utils/API/DisplayData'
import { UpdateLog } from 'Utils/Type'

import './UpdateLogs.less'

interface PropsUpdateLogs {
    isLoaded: boolean
    error: Error | null
    data: Array<UpdateLog>
}

function UpdateLogs(props: PropsUpdateLogs): React.ReactElement {
    const i18nextTranslate = useTranslation('common').t

    return (
        <div className="UpdateLogs">
            <h2>{`${i18nextTranslate('documentationLogTitle')}:`}</h2>
            <DisplayData
                isLoaded={props.isLoaded}
                error={props.error}
                component={
                    <>
                        {props.data.map((date) => (
                            <AntdRow justify="center" key={date.id}>
                                {date.updatedBy &&
                                    `${date.updatedAt} ${i18nextTranslate('by')} ${date.updatedBy}`}
                            </AntdRow>
                        ))}
                    </>
                }
            />
        </div>
    )
}

export default UpdateLogs
