import React from 'react'

import { Button as AntdButton, Row as AntdRow, Typography as AntdTypography } from 'antd'

import { useTranslation } from 'react-i18next'

import './Authentication.less'

import { backendUrlBuilder } from 'Utils/api'

const { Title } = AntdTypography

function Authentication(): React.ReactElement {
    const i18nextTranslate = useTranslation('common').t

    return (
        <AntdRow className="BackgroundAuthentication" justify="center" align="middle">
            <div className="Authentication">
                <Title level={1} className="Title">
                    CITADEL
                </Title>
                <a href={backendUrlBuilder('auth/login')}>
                    <AntdButton type="primary" className="UpperCase">
                        {i18nextTranslate('logIn')}
                    </AntdButton>
                </a>
            </div>
        </AntdRow>
    )
}

export default Authentication
