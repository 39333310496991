import React from 'react'

import './PlatformInformation.less'

import { Link as ReactRouterLink } from 'react-router-dom'

type PropsPlatformInformation = {
    onClick: () => void
}

function PlatformInformation(props: PropsPlatformInformation): React.ReactElement {
    return (
        <div className="InformationPlatform">
            <ReactRouterLink to="/home" onClick={props.onClick}>
                <h1 className="PlatformName">CITADEL</h1>
            </ReactRouterLink>
        </div>
    )
}
export default PlatformInformation
