import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import * as errors from './errors'

import { Tool, UpdateLog } from './Type'

export function errorInterceptor(error: AxiosError): Promise<Error> {
    let httpErr

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const status = error.response.status

        if (status === 401) {
            httpErr = new errors.AuthenticationError(error)
        } else if (status === 403) {
            httpErr = new errors.NotAuthorizedError(error)
        } else if (status >= 500) {
            httpErr = new errors.ServerError(status, error)
        } else if (status >= 400) {
            httpErr = new errors.ClientError(status, error)
        } else {
            httpErr = new errors.UnexpectedClientError(status, error)
        }
    } else if (error.request) {
        // The request was made but no response was received
        httpErr = new errors.TimeoutError(error)
    } else {
        // Something happened in setting up the request that triggered an Error
        httpErr = new Error(`Something happened in setting up the request that triggered an error`)
    }
    return Promise.reject(httpErr)
}

export function backendUrlBuilder(path = ''): string {
    let url = '/'

    if (process.env.REACT_APP_BACK_URL) {
        url = process.env.REACT_APP_BACK_URL
    }

    if (!url.endsWith('/')) {
        url += '/'
    }

    if (path) {
        url += path
    }
    return url
}

function getApiClient(url: string): AxiosInstance {
    const service = axios.create({
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('accessToken') || 'undefined'}`
        },
        timeout: 300000 // milliseconds (enough for export time)
    })
    service.interceptors.response.use((response) => {
        return response.data as AxiosResponse<any>
    }, errorInterceptor)
    return service
}

interface InitTool {
    getTools: () => Promise<Array<Tool>>
    editTool: (toolId: string, data: Tool) => Promise<string>
    addTool: (data: Tool) => Promise<string>
    deleteTool: (toolId: string) => Promise<string>
}

function initTool(url: string): InitTool {
    const service = getApiClient(url)
    return {
        getTools(): Promise<Tool[]> {
            return service.get('/api/tools')
        },
        editTool(toolId: string, data: Tool): Promise<string> {
            return service.post(`/api/tools/${toolId}`, data)
        },
        addTool(data: Tool): Promise<string> {
            return service.post('/api/tools', data)
        },
        deleteTool(toolId: string): Promise<string> {
            return service.post(`/api/tools/${toolId}/delete`)
        }
    }
}

interface InitDocumentation {
    getUpdateLogs: () => Promise<Array<UpdateLog>>
    update: () => Promise<string>
}

function initDocumentation(url: string): InitDocumentation {
    const service = getApiClient(url)
    return {
        getUpdateLogs(): Promise<UpdateLog[]> {
            return service.get('/api/documentation')
        },
        update(): Promise<string> {
            return service.post('/api/documentation')
        }
    }
}

interface InitGeneral {
    getInfo: () => Promise<{ name: string; email: string }>
}

function initGeneral(url: string): InitGeneral {
    const service = getApiClient(url)
    return {
        getInfo(): Promise<{
            name: string
            email: string
        }> {
            return service.get('/auth/userinfo')
        }
    }
}

let tool: InitTool | null = null
let documentation: InitDocumentation | null = null
let general: InitGeneral | null = null

export function init(): void {
    const url = backendUrlBuilder()
    if (url) {
        tool = initTool(url)
        documentation = initDocumentation(url)
        general = initGeneral(url)
    } else {
        throw new Error(`Badly initialize environment: cannot guess back url (${url})`)
    }
}

export { tool, documentation, general }
