// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Assets/Polices_CANAL/CanalBoldItalique.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Assets/Polices_CANAL/CanalLightRomain.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Assets/Police_HIND/Hind-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./Assets/Police_HIND/Hind-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./Assets/Police_HIND/Hind-Semibold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'CanalBoldItalique';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('opentype');\n}\n@font-face {\n  font-family: 'CanalLightRomain';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('opentype');\n}\n@font-face {\n  font-family: 'HindRegular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('opentype');\n}\n@font-face {\n  font-family: 'HindBold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('opentype');\n}\n@font-face {\n  font-family: 'HindSemiBold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('opentype');\n}\nbody {\n  margin: 0;\n  font-family: 'HindRegular', 'HindBold', 'HindSemiBold', 'CanalLightRomain', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\nh1 {\n  font-family: CanalBoldItalique;\n}\n", ""]);
// Exports
module.exports = exports;
