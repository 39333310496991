import React from 'react'

type svgProps = {
    className: string
}

export default ({ className }: svgProps) => (
    <span className={className}>
        <svg
            width="74"
            height="54"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M72.6667 60.3332L42.3333 29.9999C45.3333 22.3332 43.6667 13.3332 37.3333 6.99988C30.6667 0.333211 20.6667 -1.00012 12.6667 2.66654L27 16.9999L17 26.9999L2.33333 12.6665C-1.66667 20.6665 1.51992e-06 30.6665 6.66667 37.3332C13 43.6665 22 45.3332 29.6667 42.3332L60 72.6665C61.3333 73.9999 63.3333 73.9999 64.6667 72.6665L72.3333 64.9999C74 63.6665 74 61.3332 72.6667 60.3332Z"
                fill="#858585"
            />
        </svg>
    </span>
)
