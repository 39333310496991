import SearchBar, { PropsSearchBar } from 'Components/SearchBar/SearchBar'
import { searchContext } from 'Components/Tools/Tools'
import React, { useContext, useState } from 'react'
import { tool } from 'Utils/api'
import useApi from 'Utils/API/apiRequest'
import DisplayData from 'Utils/API/DisplayData'
import { getTags, searchInTools, toolsWithTags } from 'Utils/generalFunction'
import { Category, Tags, Tool } from 'Utils/Type'
import Filter from './Filter/Filter'
import './NavBar.less'
import ToolsList from './ToolsList/ToolsList'

interface SelectedTagsContextContent {
    selectedTags: Tags
    handleSelectedTagsChange: (tag: string, category: Category) => void
}

export const TAGS = React.createContext<Tags>({
    countries: [],
    environments: []
})

export const selectedTagsContext = React.createContext<SelectedTagsContextContent | null>(null)

function NavBar(props: PropsSearchBar): React.ReactElement {
    const searchValue = useContext(searchContext)

    const [selectedTags, setSelectedTags] = useState<Tags>({
        countries: [],
        environments: []
    })
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false)

    const { error, isLoaded, data } = useApi<Array<Tool>>(tool?.getTools(), [])

    function handleSelectedTagsChange(tag: string, category: Category): void {
        setSelectedTags({
            ...selectedTags,
            [category]: selectedTags[category].includes(tag)
                ? selectedTags[category].filter((value) => value !== tag)
                : [...selectedTags[category], tag]
        })
    }

    return (
        <TAGS.Provider value={getTags(data)}>
            <selectedTagsContext.Provider
                value={{
                    selectedTags,
                    handleSelectedTagsChange
                }}
            >
                <div className="SearchZone">
                    <div className="Empty" />
                    <SearchBar onChange={props.onChange} />
                    <Filter
                        isVisible={isFilterVisible}
                        setVisible={(): void => setIsFilterVisible(!isFilterVisible)}
                    />
                </div>

                <DisplayData
                    error={error}
                    isLoaded={isLoaded}
                    component={
                        <ToolsList
                            tools={toolsWithTags(selectedTags, searchInTools(searchValue, data))}
                        />
                    }
                />
            </selectedTagsContext.Provider>
        </TAGS.Provider>
    )
}

export default NavBar
