import { Button as AntdButton, Row as AntdRow } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as ReactRouterLink } from 'react-router-dom'

import './ErrorPage.less'

interface PropsErrorPage {
    errorNumber: number
}

function ErrorPage(props: PropsErrorPage): React.ReactElement {
    const i18nextTranslate = useTranslation(['common', 'errors']).t

    return (
        <AntdRow className="BackgroundErrorPage" justify="center" align="middle">
            <div className="ErrorContent">
                <h1>{`${i18nextTranslate('common:error')} ` + props.errorNumber}</h1>
                <p>{i18nextTranslate(`errors: ` + props.errorNumber)}</p>

                <ReactRouterLink to="/home">
                    <AntdButton type="primary">
                        {i18nextTranslate('common:goBackHomePage')}
                    </AntdButton>
                </ReactRouterLink>
            </div>
        </AntdRow>
    )
}

export default ErrorPage
