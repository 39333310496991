import { Tabs as AntdTabs } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './Management.less'
import ToolsManagement from './ToolsManagement/ToolsManagement'
import UpdateDocumentation from './UpdateDocumentation/UpdateDocumentation'

function Management(): React.ReactElement {
    const i18nextTranslate = useTranslation('common').t

    return (
        <div className="Management">
            <AntdTabs centered className="Tabs">
                <AntdTabs.TabPane
                    tab={
                        <span className="UpperCase Tab">{i18nextTranslate('toolsManagement')}</span>
                    }
                    key="toolsManagement"
                >
                    <ToolsManagement />
                </AntdTabs.TabPane>

                <AntdTabs.TabPane
                    tab={
                        <span className="UpperCase Tab">
                            {i18nextTranslate('updateDocumentation')}
                        </span>
                    }
                    key="updateDocumentation"
                >
                    <UpdateDocumentation />
                </AntdTabs.TabPane>
            </AntdTabs>
        </div>
    )
}

export default Management
