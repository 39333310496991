import React from 'react'

import '../Utils/CommonStyles.less'
import AppRoutes from './AppRoutes'

function App(): React.ReactElement {
    return (
        <div className="App">
            <AppRoutes />
        </div>
    )
}

export default App
