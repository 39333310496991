import React from 'react'
import { backendUrlBuilder } from 'Utils/api'
import './Documentation.less'

function Documentation(): React.ReactElement {
    return (
        <div className="Documentation">
            <iframe
                className="DocumentationInlineFrame"
                title="Documentation"
                frameBorder="0"
                src={backendUrlBuilder(
                    `api/documentation/serve/${localStorage.getItem('accessToken') || 'undefined'}/`
                )}
            ></iframe>
        </div>
    )
}

export default Documentation
