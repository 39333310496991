import React, { createContext, useState } from 'react'
import NavBar from './Navbar/NavBar'
import './Tools.less'

export const searchContext = createContext<string>('')

function Tools(): React.ReactElement {
    const [searchValue, setValue] = useState<string>('')
    return (
        <searchContext.Provider value={searchValue}>
            <NavBar onChange={(value: string): void => setValue(value)} />
        </searchContext.Provider>
    )
}

export default Tools
