import { Tag as AntdTag } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import './Tags.less'

import { Category } from 'Utils/Type'

import { selectedTagsContext } from '../../../NavBar'

interface PropsTags {
    category: Category
    listTags: Array<string>
}

function Tags(props: PropsTags): React.ReactElement {
    const i18nextTranslate = useTranslation('tag').t
    const context = useContext(selectedTagsContext)

    return (
        <div className="Tags">
            <span className="TagsCategoryName">{i18nextTranslate(props.category)}</span>
            {props.listTags.map((tag) => {
                if (tag === 'all') {
                    return null
                }
                return (
                    <AntdTag.CheckableTag
                        className="TagButton"
                        key={tag}
                        checked={
                            context === null
                                ? false
                                : context.selectedTags[props.category].includes(tag)
                        }
                        onChange={(): void =>
                            context?.handleSelectedTagsChange(tag, props.category)
                        }
                    >
                        {i18nextTranslate(tag)}
                    </AntdTag.CheckableTag>
                )
            })}
        </div>
    )
}

export default Tags
