import { Row as AntdRow, Col as AntdCol } from 'antd'
import React from 'react'

import './CardsList.less'
import Cards from './Cards/Cards'

import { Tool } from 'Utils/Type'

interface PropsCardsList {
    category?: string
    listOfTools: Array<Tool>
    editable?: boolean
}

function CardsList(props: PropsCardsList): React.ReactElement {
    return (
        <div className="CardsList">
            <h1 className="CategoryName">{props.category}</h1>
            <AntdRow>
                {props.listOfTools.map((tool) => {
                    return (
                        <AntdCol key={tool.name} xl={6}>
                            <Cards tool={tool} editable={props.editable} />
                        </AntdCol>
                    )
                })}
            </AntdRow>
        </div>
    )
}

export default CardsList
