/* TODO : Remove this */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
    EditFilled as AntdEditFilled,
    PlusCircleFilled as AntdPlusCircleFilled,
    PlusCircleOutlined as AntdPlusCircleOutlined
} from '@ant-design/icons'
import {
    Button as AntdButton,
    Form as AntdForm,
    Input as AntdInput,
    Modal as AntdModal,
    Space as AntdSpace
} from 'antd'
import { FormInstance } from 'antd/lib/form'
import { Store } from 'antd/lib/form/interface'
import Text from 'antd/lib/typography/Text'
import {
    TAGS_CATEGORIES,
    toolNamesContext,
    update
} from 'Components/Management/ToolsManagement/ToolsManagement'
import { errorNotification, successNotification } from 'Components/Notifications/Notifications'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tool } from 'Utils/api'
import { Instance, Tool } from 'Utils/Type'
import AutoCompleteField from './AutoCompleteField/AutoCompleteField'
import InstanceField from './InstanceField/InstanceField'
import './ToolEditingModal.less'

interface PropsToolEditingModal {
    tool?: Tool
}

interface InstanceInArray {
    id: number
    instance: Instance
}

function ToolEditingModal(props: PropsToolEditingModal): React.ReactElement {
    const i18nextTranslate = useTranslation(['common', 'errors', 'success']).t

    const categoriesList = useContext(TAGS_CATEGORIES).categories
    const refresh = useContext(update)

    const toolNames = useContext(toolNamesContext)

    const [isVisible, setIsVisible] = useState<boolean>(false)

    const [form] = AntdForm.useForm()

    const [instancesInEdition, setInstancesInEdition] = useState<Array<number>>([])

    function addToInstancesInEdition(id: number): void {
        setInstancesInEdition([...instancesInEdition, id])
    }

    function removeFromInstancesInEdition(id: number): void {
        setInstancesInEdition(instancesInEdition.filter((value) => value !== id))
    }

    const [id, setId] = useState<number>(props.tool?.instances.length || 0)

    function initInstances(): Array<InstanceInArray> {
        return (
            props.tool?.instances.map((instance, index) => ({
                id: index,
                instance: instance
            })) || []
        )
    }

    const [instancesArray, setInstancesArray] = useState<Array<InstanceInArray>>(initInstances())

    function updateInstance(idToUpdate: number, instance: Instance): void {
        removeFromInstancesInEdition(idToUpdate)
        instancesArray.forEach((inst) => {
            if (inst.id === idToUpdate) {
                inst.instance = instance
            }
        })
    }
    function addInstance(instance: Instance): void {
        setId(id + 1)
        addToInstancesInEdition(id)
        setInstancesArray([...instancesArray, { id: id, instance: instance }])
    }

    function removeInstance(idToDelete: number): void {
        removeFromInstancesInEdition(idToDelete)
        setInstancesArray(instancesArray.filter((instance) => instance.id !== idToDelete))
    }

    function resetFormAndInstances(): void {
        setInstancesArray(initInstances())
        setInstancesInEdition([])
        form.resetFields()
    }

    function onCancel(): void {
        resetFormAndInstances()
        setIsVisible(false)
    }

    function refreshAndCloseModal(): void {
        refresh()
        setIsVisible(false)
    }

    function onFinish(values: Store): void {
        const instances = instancesArray.map((inst) => inst.instance)

        const data: Tool = {
            category: values['category'],
            name: values['name'],
            description: values['description'],
            instances: instances
        }

        if (props?.tool?.id) {
            tool?.editTool(props.tool.id, data)
                .then(() => {
                    refreshAndCloseModal()
                    successNotification(
                        i18nextTranslate('success:toolUpdated'),
                        `${props?.tool?.name || 'undefined'} ${i18nextTranslate(
                            'success:hasBeenUpdated'
                        )}`
                    )
                })
                .catch((error: Error) => errorNotification(error.message))
        } else if (!props.tool) {
            tool?.addTool(data)
                .then(() => {
                    refreshAndCloseModal()
                    successNotification(
                        i18nextTranslate('success:toolCreated'),
                        `${data.name} ${i18nextTranslate('success:hasBeenCreated')}`
                    )
                })
                .catch((error: Error) => errorNotification(error.message))
        }
    }

    function submitForm(form: FormInstance): void {
        if (!instancesInEdition.length && instancesArray.length) {
            form.submit()
        }
    }

    return (
        <>
            {props.tool !== undefined && (
                <AntdButton
                    type="link"
                    onClick={(): void => {
                        setIsVisible(true)
                        resetFormAndInstances()
                    }}
                    className="GreyHover Black"
                    icon={<AntdEditFilled className="Medium" />}
                />
            )}
            {props.tool === undefined && (
                <AntdButton
                    type="primary"
                    className="AddNewTool"
                    onClick={(): void => {
                        setIsVisible(true)
                        resetFormAndInstances()
                    }}
                    icon={<AntdPlusCircleFilled className="Red Giant" />}
                />
            )}
            <AntdModal
                title={
                    <Text className="Medium Bold">
                        {props.tool === undefined
                            ? i18nextTranslate('common:addTool')
                            : i18nextTranslate('common:editTool')}
                    </Text>
                }
                visible={isVisible}
                onOk={(): void => submitForm(form)}
                onCancel={onCancel}
                footer={[
                    <div key="error">
                        {instancesInEdition.length > 0 && (
                            <p className="ant-form-item-explain ant-form-item-explain-error">
                                {i18nextTranslate('errors:instanceNotSaved')}
                            </p>
                        )}
                        {instancesArray.length === 0 && (
                            <p className="ant-form-item-explain ant-form-item-explain-error">
                                {i18nextTranslate('errors:noInstance')}
                            </p>
                        )}
                    </div>,
                    <AntdButton key="cancel" type="ghost" className="UpperCase" onClick={onCancel}>
                        {i18nextTranslate('common:cancel')}
                    </AntdButton>,
                    <AntdButton
                        key="save"
                        type="primary"
                        className="UpperCase"
                        disabled={instancesInEdition.length > 0 || instancesArray.length === 0}
                        onClick={(): void => submitForm(form)}
                    >
                        {i18nextTranslate('common:save')}
                    </AntdButton>
                ]}
                width={'90%'}
            >
                <AntdForm form={form} onFinish={onFinish}>
                    <AntdSpace>
                        <AntdForm.Item
                            label={i18nextTranslate('common:name')}
                            name="name"
                            initialValue={props.tool?.name}
                            rules={[
                                {
                                    required: true,
                                    message: i18nextTranslate('errors:emptyToolName')
                                },
                                {
                                    validator: (rule, value: string): Promise<void> => {
                                        return toolNames.includes(value) &&
                                            value !== props.tool?.name
                                            ? Promise.reject(
                                                  i18nextTranslate('errors:toolNameAlreadyExists')
                                              )
                                            : Promise.resolve()
                                    }
                                }
                            ]}
                        >
                            <AntdInput placeholder={i18nextTranslate('common:name')} />
                        </AntdForm.Item>

                        <AutoCompleteField
                            initialValue={props.tool?.category}
                            values={categoriesList}
                            name="category"
                            placeholder={'Category'}
                            required={true}
                        />
                    </AntdSpace>

                    <AntdForm.Item name="description" initialValue={props.tool?.description}>
                        <AntdInput.TextArea placeholder={i18nextTranslate('common:description')} />
                    </AntdForm.Item>
                </AntdForm>

                <h1>{i18nextTranslate('instances')}</h1>

                {instancesArray.map((instance) => (
                    <InstanceField
                        key={instance.id}
                        id={instance.id}
                        instance={instance.instance}
                        instances={instancesArray.map((inst) => inst.instance)}
                        instancesInEditMode={instancesInEdition}
                        removeInstance={removeInstance}
                        updateInstance={updateInstance}
                        addToInstancesInEdition={addToInstancesInEdition}
                        reset={isVisible}
                    />
                ))}

                <AntdButton
                    className="AddInstanceButton"
                    type="link"
                    onClick={(): void => {
                        addInstance({
                            name: '',
                            url: '',
                            countryName: '',
                            environmentName: ''
                        })
                    }}
                    icon={<AntdPlusCircleOutlined className="Red Huge" />}
                />
            </AntdModal>
        </>
    )
}

export default ToolEditingModal
