import { MenuInfo } from 'rc-menu/lib/interface'
import React from 'react'
import './Header.less'
import NavTabs from './NavTabs/NavTabs'
import Options from './Options/Options'
import PlatformInformation from './PlatformInformation/PlatformInformation'

export interface HeaderProps {
    selectedKey: string
    setSelectedKey: (value: string) => void
    displayMenu?: boolean
}

function Header({
    selectedKey,
    setSelectedKey,
    displayMenu = true
}: HeaderProps): React.ReactElement {
    return (
        <header className="Header">
            <PlatformInformation onClick={() => setSelectedKey('')} />

            {displayMenu ? (
                <NavTabs
                    selectedKey={selectedKey}
                    onClick={(e: MenuInfo) => {
                        setSelectedKey(e.key as string)
                    }}
                />
            ) : (
                <></>
            )}
            <Options onClick={() => setSelectedKey('')} />
        </header>
    )
}

export default Header
