export class ApiError extends Error {
    error: Error
    constructor(message: string | null = null, error: Error) {
        if (message !== null) {
            super(`${message}`)
        } else {
            super('An error occurred while contacting the api')
        }
        this.error = error
    }
}

export class ClientError extends ApiError {
    status: number
    constructor(status: number, error: Error, message: string | null = null) {
        if (message !== null) {
            super(message, error)
        } else {
            super(`An error ` + status + ` occurred while contacting the server`, error)
        }
        this.status = status
    }
}

export class UnexpectedClientError extends ClientError {
    constructor(status: number, error: Error) {
        super(
            status,
            error,
            `An unexpected error ` + status + `occurred while contacting the server`
        )
    }
}

export class AuthenticationError extends ClientError {
    constructor(error: Error) {
        super(401, error, 'The user is not authenticated.')
    }
}

export class NotAuthorizedError extends ClientError {
    constructor(error: Error) {
        super(403, error, 'The user does not have the necessary permissions.')
    }
}

export class ServerError extends ApiError {
    status: number
    constructor(status: number, error: Error) {
        super(`An error ` + status + ` occurred while contacting the server`, error)
        this.status = status
    }
}

export class TimeoutError extends ApiError {
    constructor(error: Error) {
        super('The request has timed out', error)
    }
}
