import React from 'react'

import { Input as AntdInput } from 'antd'
import { SearchOutlined as AntdSearchOutlined } from '@ant-design/icons'

import { useTranslation } from 'react-i18next'

import './SearchBar.less'

export interface PropsSearchBar {
    onChange: (value: string) => void
}

function SearchBar(props: PropsSearchBar): React.ReactElement {
    const i18nextTranslate = useTranslation('common').t
    return (
        <AntdInput
            className="SearchBar"
            placeholder={i18nextTranslate('search')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                props.onChange(e.target.value)
            }
            suffix={<AntdSearchOutlined className="Grey" />}
        />
    )
}

export default SearchBar
