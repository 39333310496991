import React, { useState } from 'react'

import { Button as AntdButton } from 'antd'
import { useTranslation } from 'react-i18next'

import DisplayData from 'Utils/API/DisplayData'
import { documentation } from 'Utils/api'
import UpdateLogs from './UpdateLogs/UpdateLogs'
import useApi from 'Utils/API/apiRequest'

function UpdateDocumentation(): React.ReactElement {
    const i18nextTranslate = useTranslation(['common', 'success']).t

    const logs = useApi(documentation?.getUpdateLogs(), [])

    const [error, setError] = useState<Error | null>(null)
    const [isLoaded, setIsLoaded] = useState<boolean>(true)
    const [data, setData] = useState<string>()

    function updateRequest(): void {
        setIsLoaded(false)
        setError(null)
      
        documentation
            ?.update()
            .then(() => {
                setIsLoaded(true)
                logs.refetch()
                setData(i18nextTranslate('success:documentationUpdated'))
            })
            .catch((error) => {
                console.log("ERROR")
                setIsLoaded(true)
                setError(error)
            })
    }
    return (
        <div className="UpdateDocumentation">
            <DisplayData isLoaded={isLoaded} error={error} component={<h1>{data}</h1>} />
            <AntdButton type="primary" onClick={(): void => updateRequest()}>
                {i18nextTranslate('updateDocumentation')}
            </AntdButton>
            <UpdateLogs {...logs} />
        </div>
    )
}

export default UpdateDocumentation
