import React, { createContext, useState } from 'react'

import SearchBar from 'Components/SearchBar/SearchBar'

import { Tool, Tags } from 'Utils/Type'
import { tool } from 'Utils/api'
import { useApi } from 'Utils/API/apiRequest'
import { getCategories, getToolNames, getTags, searchInTools } from 'Utils/generalFunction'
import ToolsList from 'Components/Tools/Navbar/ToolsList/ToolsList'
import ToolEditingModal from 'Components/ToolEditingModal/ToolEditingModal'

export const TAGS_CATEGORIES = createContext<{
    tags: Tags
    categories: Array<string>
}>({
    tags: {
        countries: [],
        environments: []
    },
    categories: []
})

export const toolNamesContext = createContext<Array<string>>([])

export const searchContext = createContext<string>('')

export const update = createContext<() => void>(() => null)

function ToolsManagement(): React.ReactElement {
    const [searchValue, setValue] = useState<string>('')

    const { data, refetch } = useApi<Array<Tool>>(tool?.getTools(), [])

    return (
        <toolNamesContext.Provider value={getToolNames(data)}>
            <TAGS_CATEGORIES.Provider
                value={{ tags: getTags(data), categories: getCategories(data) }}
            >
                <update.Provider value={(): void => refetch()}>
                    <searchContext.Provider value={searchValue}>
                        <SearchBar onChange={(value: string): void => setValue(value)} />
                        <ToolEditingModal />
                        <ToolsList tools={searchInTools(searchValue, data)} editable={true} />
                    </searchContext.Provider>
                </update.Provider>
            </TAGS_CATEGORIES.Provider>
        </toolNamesContext.Provider>
    )
}

export default ToolsManagement
